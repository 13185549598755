import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import TopLevelNav from './components/topLevelNav';
import Loadable from 'react-loadable';
import { HashRouter } from 'react-router-dom';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-material.css';
import 'react-datepicker/dist/react-datepicker.css';
import config from './config';
import { connect } from 'react-redux';
import { fetchData } from './actions/ajaxActions';

const navItems = [
  {
    label: 'Dashboard',
    to: '/dashboard',
    icon: 'DashboardIcon',
  },
  {
    exact: true,
    label: 'Administrator',
    to: '/administrator',
    icon: 'AdministrationIcon',
  },
  {
    label: 'Designer',
    to: '/designer',
    icon: 'DesignIcon',
  },
  {
    label: 'Promotions',
    to: '/promotions',
    icon: 'PromotionIcon',
  },
  {
    label: 'Media',
    to: '/gallery',
    icon: 'GalleryIcon',
  },
  {
    label: 'Membership',
    to: '/memberships',
    icon: 'MembershipIcon',
  },
  {
    label: 'Menuboard',
    to: '/menuboard',
    icon: 'MenuboardIcon',
  },
  {
    label: 'Events',
    to: '/events',
    icon: 'StockControlIcon',
  },
  {
    label: 'Reporting',
    to: '/reports',
    icon: 'ReportingIcon',
  },
  {
    label: 'Stock Control',
    to: '/stockcontrol',
    icon: 'StockControlIcon',
  },
  {
    label: 'System',
    to: '/system',
    icon: 'SystemIcon',
  },
  {
    label: 'Users',
    to: '/settings',
    icon: 'SettingsIcon',
  },
];

const loading = (props) => {
  if (props.error) {
    console.log(props.error);
  }
  return (
    <div
      style={{
        position: 'absolute',
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: 100,
        height: 100,
      }}
    >
      <h2>Loading...</h2>
    </div>
  );
};

const AdminPage = Loadable({
  loader: () => import(/* webpackChunkName: 'Admin' */ './pages/administrator/pos.js'),
  loading: loading,
});

const DesignerPage = Loadable({
  loader: () => import(/* webpackChunkName: 'Designer' */ './pages/designer/'),
  loading: loading,
});

const SettingsPage = Loadable({
  loader: () => import(/* webpackChunkName: 'Settings' */ './pages/settings/'),
  loading: loading,
});

const GalleryPage = Loadable({
  loader: () => import(/* webpackChunkName: 'Gallery' */ './pages/gallery/'),
  loading: loading,
});

const MenuBoardPage = Loadable({
  loader: () => import(/* webpackChunkName: 'MenuBoard' */ './pages/menuboard/'),
  loading: loading,
});

const StockControl = Loadable({
  loader: () => import(/* webpackChunkName: 'Stock' */ './pages/stockcontrol/'),
  loading: loading,
});

const SystemPage = Loadable({
  loader: () => import(/* webpackChunkName: 'System' */ './pages/system/'),
  loading: loading,
});

const ReportsPage = Loadable({
  loader: () => import(/* webpackChunkName: 'Reports' */ './pages/reports/'),
  loading: loading,
});

const PromotionsPage = Loadable({
  loader: () => import(/* webpackChunkName: 'Promos' */ './pages/promotions/subMenu.js'),
  loading: loading,
});

const DashboardPage = Loadable({
  loader: () => import(/* webpackChunkName: 'Dashboard' */ './pages/dashboard/'),
  loading: loading,
});

const MembershipPage = Loadable({
  loader: () => import(/* webpackChunkName: 'Member' */ './pages/membership/'),
  loading: loading,
});

const EventsPage = Loadable({
  loader: () => import(/* webpackChunkName: 'Member' */ './pages/events/'),
  loading: loading,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'JensenPOS',
      AdminPage: '',
      DesignerPage: '',
      SettingsPage: '',
      StockControlPage: '',
      DashboardPage: '',
      MembershipPage: '',
      EventsPage: '',
      dashboard: props.user.udf3,
      originalDashboard: props.user.udf3,
      selectedVenue: this.props.token.accountcode,
      editable: this.props.token.headoffice === '',
      page: '',
    };
  }

  setDashboard = (dashboard) => {
    this.setState({ dashboard });
  };

  loadNavItems = () => {
    //Permissions
    const permissionsString = this.props.user.permissions;
    const permissions = permissionsString ? JSON.parse(permissionsString) : null;

    const cleanStr = (str) => str.toLowerCase().replace(/['"\s]+/g, '');
    const appVisible = (appName) => (permissions && permissions.some((p) => cleanStr(p.fdModule) === cleanStr(appName)))
      ? permissions.some((p) => p.fdPermissions !== 'N')
      : true;

    //Nav Items
    const dashboardObject = { label: 'Dashboard', to: '/dashboard', icon: 'DashboardIcon' };
    const reportingObject = { label: 'Reporting', to: '/reports', icon: 'ReportingIcon' };
    const systemObject = { label: 'System', to: '/system', icon: 'SystemIcon' };

    if (this.props.applist === undefined) return;
    if (this.props.user && this.props.user.udf2 === 'Y') {
      return [
        dashboardObject,
        ...(appVisible('reporting') ? [reportingObject] : []),
        systemObject,
      ];
    }

    //Filter out apps that have no visible pages
    const appsArray = this.props.applist
      .filter((app) => appVisible(app.fdAppName))
      .map((app) => app.fdAppName.toLowerCase());

    const activeNavItems = navItems.filter((item) =>
      appsArray.includes(item.label.toLowerCase()),
    );

    return activeNavItems;
  };

  componentDidMount() {
    setTimeout(() => {
      this.checkStatus();
    }, 30000);
    window.addEventListener('beforeunload', this.unload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.unload);
  }

  unload = (e) => {
    let userid = this.props.user.user;
    // add params
    const options = {
      command: 'logout',
      user: userid,
    };

    // construct URL
    let url = config.url + '/portal/php/api.php';
    let bodyParams = Object.keys(options)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(options[key]);
      })
      .join('&');
    url = url + '?' + bodyParams;
    navigator.sendBeacon(url);
  };

  logout(error) {
    if (!error) error = 'Session timed out';
    this.props.dispatch({
      error,
      type: 'INVALID_TOKEN',
    });
  }

  force_logout() {
    this.props.dispatch({
      error: 'Session logged out',
      type: 'INVALID_TOKEN',
    });
  }

  checkStatus() {
    const opts = {
      command: 'alive',
      token: this.props.user.token,
    };
    let bodyParams = Object.keys(opts)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(opts[key]);
      })
      .join('&');

    fetch(config.url + '/portal/php/api.php', {
      method: 'post',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: bodyParams,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then((json) => {
              if (json.success) {
                setTimeout(() => {
                  this.checkStatus();
                }, 30000);
              } else {
                this.logout(json.errors[0].error);
              }
            })
            .catch((error) => {
              this.logout();
            });
        } else {
          this.logout();
        }
      })
      .catch((error) => {
        this.logout();
      });
  }

  handleVenueChange(pincode, selectedVenue, storeName, editable) {
    //API Call to set Session Variable
    const { token } = this.props;
    let formData = new FormData();
    formData.append('token', token.token);
    formData.append('command', 'setVenue');
    formData.append('domain', 'administrator');
    formData.append('accountPin', pincode);
    formData.append('accountCode', selectedVenue.value);
    fetch(config.url + '/apps/connector/api.php', {
      method: 'post',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then((json) => {
              if (json.success) {
              } else console.log('Error', json.errors);
            })
            .catch((error) => console.log('Error in response', error));
          if (selectedVenue.value === this.state.selectedVenue) return;
          this.setState({ selectedVenue: selectedVenue.value, editable });
          if(typeof storeName === 'string') this.props.dispatch(fetchData(storeName));
          if(typeof storeName === 'array') {
            storeName.forEach((store) => {
              this.props.dispatch(fetchData(store));
            });
          }
        } else console.log('error', response);
      })
      .catch((error) => console.log('Request failed', error));
  }

  handlePageChange(page) {
    this.setState({ page });
  }

  handleFilterModelChange(filterModel) {
    this.setState({ filterModel });
  }

  render() {
    const navList = this.loadNavItems();
    const myNavItems = navList === undefined ? [] : navList;
    const { selectedVenue, editable, page, filterModel } = this.state;
    const { token, dispatch, username } = this.props;

    const onVenueChange = (pincode, selectedVenue, storeName, editable) => {
      this.handleVenueChange(pincode, selectedVenue, storeName, editable);
    };
    const onPageChange = (page) => this.handlePageChange(page);
    const onFilterModelChange = (filterModel) =>
      this.handleFilterModelChange(filterModel);

    const MyAdminPage = (props) => {
      return (
        <AdminPage
          token={token}
          dispatch={dispatch}
          selectedVenue={selectedVenue}
          page={page}
          editable={editable}
          onVenueChange={onVenueChange}
          onPageChange={onPageChange}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          {...props}
        />
      );
    };

    const MyDesignerPage = (props) => {
      return (
        <DesignerPage
          token={token}
          dispatch={dispatch}
          selectedVenue={selectedVenue}
          page={page}
          editable={editable}
          onVenueChange={onVenueChange}
          onPageChange={onPageChange}
          {...props}
        />
      );
    };

    const MySettingsPage = (props) => {
      return <SettingsPage username={username} token={token} {...props} />;
    };

    const MyGalleryPage = (props) => {
      return (
        <GalleryPage
          token={token}
          dispatch={dispatch}
          selectedVenue={selectedVenue}
          page={page}
          editable={editable}
          onVenueChange={onVenueChange}
          onPageChange={onPageChange}
          {...props}
        />
      );
    };

    const MyMenuBoardPage = (props) => {
      return (
        <MenuBoardPage
          token={token}
          dispatch={dispatch}
          selectedVenue={selectedVenue}
          page={page}
          editable={editable}
          onVenueChange={onVenueChange}
          onPageChange={onPageChange}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          {...props}
        />
      );
    };

    const MyStockControlPage = (props) => {
      return (
        <StockControl
          token={token}
          dispatch={dispatch}
          selectedVenue={selectedVenue}
          page={page}
          editable={editable}
          onVenueChange={onVenueChange}
          onPageChange={onPageChange}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          {...props}
        />
      );
    };

    const MySystemPage = (props) => {
      return <SystemPage token={token} {...props} />;
    };

    const MyReportsPage = (props) => {
      return (
        <ReportsPage
          token={token}
          selectedVenue={selectedVenue}
          page={page}
          editable={editable}
          onVenueChange={onVenueChange}
          onPageChange={onPageChange}
          {...props}
        />
      );
    };

    const MyPromotionsPage = (props) => {
      return (
        <PromotionsPage
          token={token}
          dispatch={dispatch}
          selectedVenue={selectedVenue}
          page={page}
          editable={editable}
          onVenueChange={onVenueChange}
          onPageChange={onPageChange}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          {...props}
        />
      );
    };

    const MyDashboardPage = (props) => {
      return (
        <DashboardPage
          dashboardUser={this.props.user.udf2 === 'Y'}
          dashboard={this.state.dashboard}
          originalDashboard={this.state.originalDashboard}
          setDashboard={(db) => this.setDashboard(db)}
          {...props}
        />
      );
    };

    const MyMembershipPage = (props) => {
      return (
        <MembershipPage
          token={token}
          dispatch={dispatch}
          selectedVenue={selectedVenue}
          page={page}
          editable={editable}
          onVenueChange={onVenueChange}
          onPageChange={onPageChange}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          {...props}
        />
      );
    };

    const MyEventsPage = (props) => {
      return (
        <EventsPage
          token={token}
          dispatch={dispatch}
          selectedVenue={selectedVenue}
          page={page}
          editable={editable}
          onVenueChange={onVenueChange}
          onPageChange={onPageChange}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          {...props}
        />
      );
    };

    if (this.props.user && this.props.user.udf2 === 'Y') {
      return (
        <HashRouter>
          <Route
            render={({ location }) => (
              <div className="flex flex-grow" style={{ maxHeight: '100vh' }}>
                <TopLevelNav myNavItems={myNavItems} />
                <div className="flex flex-col flex-grow bg-white rounded-tl-3xl">
                  <Switch key={location.key}>
                    <Route
                      path="/reports"
                      location={location}
                      component={MyReportsPage}
                    />
                    <Route
                      path="/settings"
                      location={location}
                      component={MySettingsPage}
                    />
                    <Route path="/" location={location} component={MyDashboardPage} />
                  </Switch>
                </div>
              </div>
            )}
          />
        </HashRouter>
      );
    }

    return (
      <HashRouter>
        <Route
          render={({ location }) => (
            <div className="flex flex-grow" style={{ maxHeight: '100vh' }}>
              <TopLevelNav myNavItems={myNavItems} />
              <div className="flex flex-col flex-grow bg-white rounded-tl-3xl">
                <Switch key={location.key}>
                  <Route
                    path="/designer"
                    location={location}
                    component={MyDesignerPage}
                  />
                  <Route
                    path="/administrator"
                    location={location}
                    component={MyAdminPage}
                  />
                  <Route
                    path="/promotions"
                    location={location}
                    component={MyPromotionsPage}
                  />
                  <Route path="/reports" location={location} component={MyReportsPage} />
                  <Route path="/gallery" location={location} component={MyGalleryPage} />
                  <Route
                    path="/menuboard"
                    location={location}
                    component={MyMenuBoardPage}
                  />
                  <Route
                    path="/stockcontrol"
                    location={location}
                    component={MyStockControlPage}
                  />
                  <Route path="/system" location={location} component={MySystemPage} />
                  <Route
                    path="/settings"
                    location={location}
                    component={MySettingsPage}
                  />
                  <Route
                    path="/memberships"
                    location={location}
                    component={MyMembershipPage}
                  />
                  <Route
                    path="/events"
                    location={location}
                    component={MyEventsPage}
                  />
                  <Route path="/" location={location} component={MyDashboardPage} />
                </Switch>
              </div>
            </div>
          )}
        />
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.token,
});

export default connect(mapStateToProps)(App);
