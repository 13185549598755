import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createAjaxReducer from './reducers/createAjaxReducer';
import tokenReducer from './reducers/tokenreducers';
import pluginsReducer from './reducers/pluginsReducer';
import userappsReducer from './reducers/userappsReducer';
// import dashboardReducers from './reducers/dashboardReducers'

let rootReducer = combineReducers({
  accountapps: createAjaxReducer('ACCOUNTAPPS'),
  accounts: createAjaxReducer('ACCOUNTS'),
  activityLogTest: createAjaxReducer('ACTIVITYLOGTEST'),
  advertisingAvailability: createAjaxReducer('ADVERTISINGAVAILABILITY'),
  advertisingGroups: createAjaxReducer('ADVERTISINGGROUPS'),
  appregister: createAjaxReducer('APPREGISTER'),
  autostartapps: createAjaxReducer('AUTOSTARTAPPS'),
  barcodes: createAjaxReducer('BARCODES'),
  buttonLevels: createAjaxReducer('BUTTONLEVELS'),
  buttonList: createAjaxReducer('BUTTONLIST'),
  campaign: createAjaxReducer('CAMPAIGN'),
  categories: createAjaxReducer('CATEGORIES'),
  countCurrent: createAjaxReducer('COUNTCURRENT'),
  countLocations: createAjaxReducer('COUNTLOCATIONS'),
  counts: createAjaxReducer('COUNTS'),
  countStockDaily: createAjaxReducer('COUNTSTOCKDAILY'),
  coupons: createAjaxReducer('COUPONS'),
  couponsissued: createAjaxReducer('COUPONSISSUED'),
  couponcriteriasets: createAjaxReducer('COUPONCRITERIASETS'),
  couponcriteriasetitems: createAjaxReducer('COUPONCRITERIASETITEMS'),
  couponrestrictions: createAjaxReducer('COUPONRESTRICTIONS'),
  couponavailability: createAjaxReducer('COUPONAVAILABILITY'),
  couponactionparams: createAjaxReducer('COUPONACTIONPARAMS'),
  couponlayouts: createAjaxReducer('COUPONLAYOUTS'),
  courses: createAjaxReducer('COURSES'),
  customerDisplayLayouts: createAjaxReducer('CUSTOMERDISPLAYLAYOUTS'),
  customerDisplayItems: createAjaxReducer('CUSTOMERDISPLAYITEMS'),
  dashboards: createAjaxReducer('DASHBOARDS'),
  dashboardWidgets: createAjaxReducer('DASHBOARDWIDGETS'),
  departments: createAjaxReducer('DEPARTMENTS'),
  destinations: createAjaxReducer('DESTINATIONS'),
  devices: createAjaxReducer('DEVICES'),
  discountGroups: createAjaxReducer('DISCOUNTGROUPS'),
  discountReasons: createAjaxReducer('DISCOUNTREASONS'),
  eftpos: createAjaxReducer('EFTPOS'),
  employeeDetails: createAjaxReducer('EMPLOYEEDETAILS'),
  eos: createAjaxReducer('EOS'),
  floorPlans: createAjaxReducer('FLOORPLANS'),
  events: createAjaxReducer('EVENTS'),
  eventrecs: createAjaxReducer('EVENTRECS'),
  hideFloorPlans: createAjaxReducer('HIDEFLOORPLANS'),
  history: createAjaxReducer('HISTORY'),
  ingredients: createAjaxReducer('INGREDIENTS'),
  journal: createAjaxReducer('JOURNAL'),
  journalItems: createAjaxReducer('JOURNALITEMS'),
  kitchenMessages: createAjaxReducer('KITCHENMESSAGES'),
  locationLayouts: createAjaxReducer('LOCATIONLAYOUTS'),
  locations: createAjaxReducer('LOCATIONS'),
  log: createAjaxReducer('LOG'),
  mailshot: createAjaxReducer('MAILSHOT'),
  mainGroups: createAjaxReducer('MAINGROUPS'),
  manualSales: createAjaxReducer('MANUALSALES'),
  membership: createAjaxReducer('MEMBERSHIP'),
  membershiptypes: createAjaxReducer('MEMBERSHIPTYPES'),
  membershiplevels: createAjaxReducer('MEMBERSHIPLEVELS'),
  membershipSignUpForms: createAjaxReducer('MEMBERSHIPSIGNUPFORMS'),
  membershipSignUpFields: createAjaxReducer('MEMBERSHIPSIGNUPFIELDS'),
  membershipSignUpFormFields: createAjaxReducer('MEMBERSHIPSIGNUPFORMFIELDS'),
  menuboard: createAjaxReducer('MENUBOARD'),
  menuBoardParams: createAjaxReducer('MENUBOARDPARAMS'),
  menuList: createAjaxReducer('MENULIST'),
  mmd: createAjaxReducer('MMD'),
  modGroupItems: createAjaxReducer('MODGROUPITEMS'),
  modGroups: createAjaxReducer('MODGROUPS'),
  mods: createAjaxReducer('MODS'),
  modulelist: createAjaxReducer('MODULELIST'),
  nominalCodes: createAjaxReducer('NOMINALCODES'),
  oms: createAjaxReducer('OMS'),
  omsdepartments: createAjaxReducer('OMSDEPARTMENTS'),
  omsdocketdropdown: createAjaxReducer('OMSDOCKETDROPDOWN'),
  omsgetdocket: createAjaxReducer('OMSGETDOCKET'),
  omshistory: createAjaxReducer('OMSHISTORY'),
  omsParams: createAjaxReducer('OMSPARAMS'),
  omsplus: createAjaxReducer('OMSPLUS'),
  omstransaction: createAjaxReducer('OMSTRANSACTION'),
  omstransactionitem: createAjaxReducer('OMSTRANSACTIONITEM'),
  parameters: createAjaxReducer('PARAMETERS'),
  params: createAjaxReducer('PARAMS'),
  paymentLayouts: createAjaxReducer('PAYMENTLAYOUTS'),
  paymentTypes: createAjaxReducer('PAYMENTTYPES'),
  pluavailability: createAjaxReducer('PLUAVAILABILITY'),
  pluBarcodes: createAjaxReducer('PLUBARCODES'),
  plugins: pluginsReducer,
  pluGroupItems: createAjaxReducer('PLUGROUPITEMS'),
  pluGroups: createAjaxReducer('PLUGROUPS'),
  plus: createAjaxReducer('PLUS'),
  pos: createAjaxReducer('POS'),
  posLayouts: createAjaxReducer('POSLAYOUTS'),
  poslicence: createAjaxReducer('POSLICENCE'),
  posParams: createAjaxReducer('POSPARAMS'),
  priceLevels: createAjaxReducer('PRICELEVELS'),
  pricescheduleavailability: createAjaxReducer('PRICESCHEDULEAVAILABILITY'),
  printers: createAjaxReducer('PRINTERS'),
  printGroupExcludedPOS: createAjaxReducer('PRINTGROUPEXCLUDEDPOS'),
  printGroupPrinters: createAjaxReducer('PRINTGROUPPRINTERS'),
  printGroups: createAjaxReducer('PRINTGROUPS'),
  printTemplates: createAjaxReducer('PRINTTEMPLATES'),
  productBarcodes: createAjaxReducer('PRODUCTBARCODES'),
  products: createAjaxReducer('PRODUCTS'),
  productStockGroups: createAjaxReducer('PRODUCTSTOCKGROUPS'),
  productVariants: createAjaxReducer('PRODUCTVARIANTS'),
  promoavailability: createAjaxReducer('PROMOAVAILABILITY'),
  promopriceschedule: createAjaxReducer('PROMOPRICESCHEDULE'),
  promotionactionparams: createAjaxReducer('PROMOTIONACTIONPARAMS'),
  promotional: createAjaxReducer('PROMOTIONAL'),
  promotioncriteriasetitems: createAjaxReducer('PROMOTIONCRITERIASETITEMS'),
  promotioncriteriasets: createAjaxReducer('PROMOTIONCRITERIASETS'),
  promotiongroups: createAjaxReducer('PROMOTIONGROUPS'),
  promotions: createAjaxReducer('PROMOTIONS'),
  promotriggerrestrictions: createAjaxReducer('PROMOTRIGGERRESTRICTIONS'),
  purchasing: createAjaxReducer('PURCHASING'),
  purchasingitems: createAjaxReducer('PURCHASINGITEMS'),
  reasons: createAjaxReducer('REASONS'),
  reportCriteria: createAjaxReducer('REPORTCRITERIA'),
  venuesReports: createAjaxReducer('VENUESREPORTS'),
  masterReports: createAjaxReducer('MASTERREPORTS'),
  reportschedule: createAjaxReducer('REPORTSCHEDULE'),
  reportScheduleReports: createAjaxReducer('REPORTSCHEDULEREPORTS'),
  sales: createAjaxReducer('SALES'),
  salesPeriods: createAjaxReducer('SALESPERIODS'),
  saleTypes: createAjaxReducer('SALETYPES'),
  savedReports: createAjaxReducer('SAVEDREPORTS'),
  scheduleTasks: createAjaxReducer('SCHEDULETASKS'),
  serviceTimeParams: createAjaxReducer('SERVICETIMEPARAMS'),
  setMenuItems: createAjaxReducer('SETMENUITEMS'),
  setMenus: createAjaxReducer('SETMENUS'),
  sizes: createAjaxReducer('SIZES'),
  staff: createAjaxReducer('STAFF'),
  staffGroups: createAjaxReducer('STAFFGROUPS'),
  stock: createAjaxReducer('STOCK'),
  subGroups: createAjaxReducer('SUBGROUPS'),
  subLocations: createAjaxReducer('SUBLOCATIONS'),
  supplierProducts: createAjaxReducer('SUPPLIERPRODUCTS'),
  suppliers: createAjaxReducer('SUPPLIERS'),
  surcharges: createAjaxReducer('SURCHARGES'),
  surchargegroups: createAjaxReducer('SURCHARGEGROUPS'),
  surchargeavailability: createAjaxReducer('SURCHARGEAVAILABILITY'),
  surchargerestrictions: createAjaxReducer('SURCHARGERESTRICTIONS'),
  surchargetriggers: createAjaxReducer('SURCHARGETRIGGERS'),
  systemmodules: createAjaxReducer('SYSTEMMODULES'),
  systemplugins: createAjaxReducer('SYSTEMPLUGINS'),
  tables: createAjaxReducer('TABLES'),
  tablerecs: createAjaxReducer('TABLERECS'),
  tabs: createAjaxReducer('TABS'),
  takings: createAjaxReducer('TAKINGS'),
  tax: createAjaxReducer('TAX'),
  token: tokenReducer,
  transactions: createAjaxReducer('TRANSACTIONS'),
  transferitems: createAjaxReducer('TRANSFERITEMS'),
  transfers: createAjaxReducer('TRANSFERS'),
  ums: createAjaxReducer('UMS'),
  userapps: userappsReducer,
  users: createAjaxReducer('USERS'),
  userTableColumns: createAjaxReducer('USERTABLECOLUMNS'),
  pagepermissions: createAjaxReducer('PAGEPERMISSIONS'),
  venueItems: createAjaxReducer('VENUEITEMS'),
  wastage: createAjaxReducer('WASTAGE'),
  wastageitems: createAjaxReducer('WASTAGEITEMS'),
  writeoffs: createAjaxReducer('WRITEOFFS'),
  zones: createAjaxReducer('ZONES'),
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
