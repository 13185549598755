import { makeAjaxRequest } from './createAjaxActions';

const defaults = {
  fetchCommand: 'entries.list',
  removeCommand: 'entry.remove',
  updateCommand: 'data.update',
  createCommand: 'entry.add',
  domain: 'administrator',
};

const stores = {
  ACCOUNTAPPS: {
    ...defaults,
    tb: 'accountapps',
    table: 'tbaccountapps',
    domain: 'settings',
  },
  ACCOUNTS: {
    tb: 'accounts',
    table: 'tbaccounts',
    domain: 'administrator',
    fetchCommand: 'venues.list',
  },
  ACTIVITYLOGTEST: {
    ...defaults,
    tb: 'activitylog',
    table: 'tbactivitylog',
  },
  ADVERTISINGAVAILABILITY: {
    ...defaults,
    tb: 'advertisingavailability',
    table: 'tbadvertisingavailability',
  },
  ADVERTISINGGROUPS: {
    ...defaults,
    tb: 'advertisinggroups',
    table: 'tbadvertisinggroups',
  },
  APPREGISTER: {
    ...defaults,
    tb: 'appregister',
    table: 'tbappregister',
    domain: 'settings',
  },
  BARCODES: {
    ...defaults,
    tb: 'barcodes',
    table: 'tbbarcodes',
  },
  BUTTONLIST: {
    ...defaults,
    tb: 'buttons',
    table: 'tbbuttons',
    domain: 'designer',
  },
  BUTTONLEVELS: {
    ...defaults,
    tb: 'buttonlevels',
    table: 'tbbuttonlevels',
  },
  CAMPAIGN: {
    ...defaults,
    tb: 'campaigns',
    table: 'tbcampaigns',
    domain: 'memberships',
  },
  CATEGORIES: {
    ...defaults,
    tb: 'categories',
    table: 'tbcategories',
  },
  COUNTS: {
    ...defaults,
    tb: 'counts',
    table: 'tbcounts',
    domain: 'stock control',
  },
  COUNTCURRENT: {
    ...defaults,
    tb: 'count_current',
    table: 'tbcount_current',
    domain: 'stock control',
  },
  COUNTLOCATIONS: {
    ...defaults,
    tb: 'count_locations',
    table: 'tbcount_locations',
    domain: 'stock control',
  },
  COUNTSTOCKDAILY: {
    ...defaults,
    tb: 'count_stock_daily',
    table: 'tbcount_stock_daily',
    domain: 'stock control',
  },
  COUPONS: {
    ...defaults,
    tb: 'coupons',
    table: 'tbcoupons',
    domain: 'promotions',
  },
  COUPONSISSUED: {
    ...defaults,
    tb: 'couponsissued',
    table: 'tbcouponsissued',
    domain: 'promotions',
  },
  COUPONCRITERIASETS: {
    ...defaults,
    tb: 'couponcriteriasets',
    table: 'tbcouponcriteriasets',
    domain: 'promotions',
  },
  COUPONCRITERIASETITEMS: {
    ...defaults,
    tb: 'couponcriteriasetitems',
    table: 'tbcouponcriteriasetitems',
    domain: 'promotions',
  },
  COUPONRESTRICTIONS: {
    ...defaults,
    tb: 'couponrestrictions',
    table: 'tbcouponrestrictions',
    domain: 'promotions',
  },
  COUPONAVAILABILITY: {
    ...defaults,
    tb: 'couponavailability',
    table: 'tbcouponavailability',
    domain: 'promotions',
  },
  COUPONACTIONPARAMS: {
    ...defaults,
    tb: 'couponactionparams',
    table: 'tbcouponactionparams',
    domain: 'promotions',
  },
  COUPONLAYOUTS: {
    ...defaults,
    tb: 'couponlayouts',
    table: 'tbcouponlayouts',
  },
  COURSES: {
    ...defaults,
    tb: 'course',
    table: 'tbcourse',

    sequenceField: 'fdOrder',
  },
  CUSTOMERDISPLAYLAYOUTS: {
    ...defaults,
    tb: 'customerdisplaylayouts',
    table: 'tbcustomerdisplaylayouts',
  },
  CUSTOMERDISPLAYITEMS: {
    ...defaults,
    tb: 'customerdisplayitems',
    table: 'tbcustomerdisplayitems',
  },
  DASHBOARDS: {
    ...defaults,
    tb: 'dashboards',
    table: 'tbdashboards',
  },
  DASHBOARDWIDGETS: {
    ...defaults,
    tb: 'dashboardwidgets',
    table: 'tbdashboardwidgets',
  },
  DEPARTMENTS: {
    ...defaults,
    tb: 'departments',
    table: 'tbdepartments',

    sequenceField: 'fdSeq',
  },
  DESTINATIONS: {
    ...defaults,
    tb: 'destinations',
    table: 'tbdestinations',
  },
  DEVICES: {
    ...defaults,
    tb: 'devices',
    table: 'tbdevices',
  },
  DISCOUNTGROUPS: {
    ...defaults,
    tb: 'discountgroups',
    table: 'tbdiscountgroups',
  },
  DISCOUNTREASONS: {
    ...defaults,
    tb: 'discountreasons',
    table: 'tbdiscountreasons',
  },
  EFTPOS: {
    ...defaults,
    tb: 'eftpos',
  },
  EMPLOYEEDETAILS: {
    ...defaults,
    tb: 'buttonlevels',
    table: 'tbbuttonlevels',
  },
  EOS: {
    ...defaults,
    tb: 'transactions',
    fields: 'COUNT(DISTINCT fdOrderNumber) AS fdCount',
  },
  FLOORPLANS: {
    ...defaults,
    tb: 'floorplans',
    table: 'tbfloorplans',
    domain: 'designer',
  },
  EVENTS: {
    ...defaults,
    tb: 'events',
    table: 'tbevents',
    domain: 'administrator',
  },
  EVENTRECS: {
    ...defaults,
    tb: 'eventrecs',
    table: 'tbeventrecs',
  },
  HIDEFLOORPLANS: {
    ...defaults,
    tb: 'hidefloorplans',
    table: 'tbhidefloorplans',
  },
  HISTORY: {
    ...defaults,
    tb: 'history',
  },
  INGREDIENTS: {
    ...defaults,
    tb: 'ingredients',
    table: 'tbingredients',
  },
  JOURNAL: {
    ...defaults,
    tb: 'journal',
    table: 'tbjournal',
    orderby: 'fdDateTime DESC',
  },
  JOURNALITEMS: {
    ...defaults,
    tb: 'journalitems',
    table: 'tbjournalitems',
    orderby: 'fdSeq',
  },
  KITCHENMESSAGES: {
    ...defaults,
    tb: 'kitchenmsgs',
    table: 'tbkitchenmsgs',
  },
  LOCATIONLAYOUTS: {
    ...defaults,
    tb: 'locationlayouts',
    table: 'tblocationlayouts',
  },
  LOCATIONS: {
    ...defaults,
    tb: 'locations',
    table: 'tblocations',
  },
  LOG: {
    ...defaults,
    tb: 'log',
    table: 'tblog',
    domain: 'system',
  },
  MAILSHOT: {
    ...defaults,
    tb: 'mailshot_designs',
    table: 'tbmailshot_designs',
    domain: 'memberships',
  },
  MAINGROUPS: {
    ...defaults,
    tb: 'maingroups',
    table: 'tbmaingroups',
    sequenceField: 'fdSeq',
  },
  MANUALSALES: {
    ...defaults,
    tb: 'manualsales',
    table: 'tbmanualsales',
    domain: 'stock control',
  },
  MASTERREPORTS: {
    ...defaults,
    domain: 'reporting',
    fetchCommand: 'masterreports.list',
    removeCommand: 'report.remove',
    updateCommand: 'report.update',
    createCommand: 'report.add',
  },
  MEMBERSHIPTYPES: {
    ...defaults,
    tb: 'membertypes',
    table: 'tbmembertypes',
    domain: 'memberships',
  },
  MEMBERSHIPLEVELS: {
    ...defaults,
    tb: 'membershiplevels',
    table: 'tbmembershiplevels',
    domain: 'memberships',
  },
  MEMBERSHIP: {
    ...defaults,
    tb: 'members',
    table: 'tbmembers',
    domain: 'memberships',
  },
  MEMBERSHIPSIGNUPFORMS: {
    ...defaults,
    tb: 'membersignupforms',
    table: 'tbmembersignupforms',
    domain: 'memberships',
  },
  MEMBERSHIPSIGNUPFORMFIELDS: {
    ...defaults,
    tb: 'membersignupformfields',
    table: 'tbmembersignupformfields',
    domain: 'memberships',
    sequenceField: 'fdSeq',
    groupField: 'fdFormID',
  },
  MEMBERSHIPSIGNUPFIELDS: {
    ...defaults,
    tb: 'membersignupfields',
    table: 'tbmembersignupfields',
    domain: 'memberships',
  },
  MENUBOARD: {
    ...defaults,
    tb: 'menuboard',
    table: 'tbmenuboard',
    sequenceField: 'fdSeq',
  },
  MENUBOARDPARAMS: {
    ...defaults,
    tb: 'menuboardparams',
    table: 'tbmenuboardparams',
    updateCommand: 'menuboardparam_update',
  },
  MENULIST: {
    ...defaults,
    tb: 'menus',
    table: 'tbmenus',
    domain: 'designer',
  },
  MMD: {
    ...defaults,
    tb: 'mmd',
    table: 'tbmmd',
  },
  MODGROUPS: {
    ...defaults,
    tb: 'modgroups_new',
    table: 'tbmodgroups_new',
  },
  MODGROUPITEMS: {
    ...defaults,
    tb: 'modgroupitems',
    table: 'tbmodgroupitems',
  },
  MODS: {
    ...defaults,
    tb: 'plu',
    table: 'tbplu',
  },
  MODULELIST: {
    fetchCommand: 'modules.list',
    domain: 'administrator',
  },
  NOMINALCODES: {
    ...defaults,
    tb: 'nominalcodes',
    table: 'tbnominalcodes',
  },
  OMS: {
    ...defaults,
    tb: 'orderdisplaydevices',
    table: 'tborderdisplaydevices',
  },
  OMSDEPARTMENTS: {
    ...defaults,
    fetchCommand: 'oms.departments',
  },
  OMSDOCKETDROPDOWN: {
    ...defaults,
    fetchCommand: 'oms.docket.dropdown',
  },
  OMSGETDOCKET: {
    ...defaults,
    fetchCommand: 'oms.get.docket',
  },
  OMSHISTORY: {
    ...defaults,
    fetchCommand: 'oms.history',
  },
  OMSPARAMS: {
    ...defaults,
    tb: 'omsparams',
    table: 'tbomsparams',
    updateCommand: 'omsparam_update',
  },
  OMSPLUS: {
    ...defaults,
    fetchCommand: 'oms.plus',
  },
  OMSTRANSACTION: {
    ...defaults,
    fetchCommand: 'oms.transaction',
  },
  OMSTRANSACTIONITEM: {
    ...defaults,
    fetchCommand: 'oms.transactionitems',
  },
  PARAMETERS: {
    ...defaults,
    tb: 'parameters',
    table: 'tbparameters',
  },
  PARAMS: {
    ...defaults,
    tb: 'params',
    table: 'tbparams',
  },
  PAYMENTLAYOUTS: {
    ...defaults,
    tb: 'paymentlayouts',
    table: 'tbpaymentlayouts',
  },
  PAYMENTTYPES: {
    ...defaults,
    tb: 'paymenttypes',
    table: 'tbpaymenttypes',

    sequenceField: 'fdIndex',
  },
  PLUAVAILABILITY: {
    ...defaults,
    tb: 'pluavailability',
    table: 'tbpluavailability',
  },
  PLUBARCODES: {
    ...defaults,
    tb: 'plubarcodes',
    table: 'tbplubarcodes',
  },
  PLUGINS: {
    ...defaults,
    tb: 'plugins',
    table: 'tbplugins',
  },
  PLUGROUPITEMS: {
    ...defaults,
    tb: 'plugroupitems',
    table: 'tbplugroupitems',
  },
  PLUGROUPS: {
    ...defaults,
    tb: 'plugroups',
    table: 'tbplugroups',
  },
  PLUS: {
    ...defaults,
    tb: 'plu',
    table: 'tbplu',
  },
  POS: {
    ...defaults,
    tb: 'pos',
    table: 'tbpos',
  },
  POSLAYOUTS: {
    ...defaults,
    tb: 'poslayouts',
    table: 'tbposlayouts',
  },
  POSPARAMS: {
    ...defaults,
    tb: 'posparams',
    table: 'tbposparams',
    updateCommand: 'posparam_update',
  },
  PRICELEVELS: {
    ...defaults,
    tb: 'pricelevels',
    table: 'tbpricelevels',
    updateCommand: 'entry.update',
    domain: 'administrator',
  },
  PRICESCHEDULEAVAILABILITY: {
    ...defaults,
    tb: 'promopricescheduleavailability',
    table: 'tbpromopricescheduleavailability',
    domain: 'promotions',
  },
  PRINTERS: {
    ...defaults,
    tb: 'printers',
    table: 'tbprinters',
  },
  PRINTGROUPS: {
    ...defaults,
    tb: 'printergroup',
    table: 'tbprintergroup',
  },
  PRINTGROUPEXCLUDEDPOS: {
    ...defaults,
    tb: 'printgroupexcludedpos',
    table: 'tbprintgroupexcludedpos',
  },
  PRINTGROUPPRINTERS: {
    ...defaults,
    tb: 'printgroupprinters',
    table: 'tbprintgroupprinters',
  },
  PRINTTEMPLATES: {
    ...defaults,
    tb: 'templates',
    table: 'tbtemplates',
  },
  PRODUCTBARCODES: {
    ...defaults,
    tb: 'productbarcodes',
    table: 'tbproductbarcodes',
  },
  PRODUCTS: {
    ...defaults,
    tb: 'products',
    table: 'tbproducts',
  },
  PRODUCTVARIANTS: {
    ...defaults,
    tb: 'productvariants',
    table: 'tbproductvariants',
  },
  PRODUCTSTOCKGROUPS: {
    ...defaults,
    tb: 'prodstockgroups',
    table: 'tbprodstockgroups',
  },
  PROMOAVAILABILITY: {
    ...defaults,
    tb: 'promoavailability',
    table: 'tbpromoavailability',
    domain: 'promotions',
  },
  PROMOPRICESCHEDULE: {
    ...defaults,
    tb: 'promopriceschedule',
    table: 'tbpromopriceschedule',
    domain: 'promotions',
  },
  PROMOTIONACTIONPARAMS: {
    ...defaults,
    tb: 'promoactionparams',
    table: 'tbpromoactionparams',
    domain: 'promotions',
  },
  PROMOTIONAL: {
    ...defaults,
    tb: 'promos',
    table: 'tbpromos',
    domain: 'promotions',
  },
  PROMOTIONCRITERIASETITEMS: {
    ...defaults,
    tb: 'promocriteriasetitems',
    table: 'tbpromocriteriasetitems',
    domain: 'promotions',
  },
  PROMOTIONCRITERIASETS: {
    ...defaults,
    tb: 'promocriteriasets',
    table: 'tbpromocriteriasets',
    domain: 'promotions',
    sequenceField: 'fdSeq',
  },
  PROMOTIONGROUPS: {
    ...defaults,
    tb: 'promogroups',
    table: 'tbpromogroups',
    domain: 'promotions',
  },
  PROMOTIONS: {
    ...defaults,
    tb: 'promotions',
    table: 'tbpromotions',
    domain: 'promotions',
    sequenceField: 'fdSeq',
  },
  PROMOTRIGGERRESTRICTIONS: {
    ...defaults,
    tb: 'promotriggerrestrictions',
    table: 'tbpromotriggerrestrictions',
  },
  PURCHASING: {
    ...defaults,
    tb: 'purchasing',
    table: 'tbpurchasing',
    domain: 'stock control',
  },
  PURCHASINGITEMS: {
    ...defaults,
    tb: 'purchasingitems',
    table: 'tbpurchasingitems',
    domain: 'stock control',
  },
  REASONS: {
    ...defaults,
    tb: 'reasons',
    table: 'tbreasons',
  },
  REPORTCRITERIA: {
    ...defaults,
    domain: 'reporting',
    fetchCommand: 'getCriteria',
  },
  REPORTSCHEDULE: {
    ...defaults,
    domain: 'reporting',
    fetchCommand: 'schedule.list',
    removeCommand: 'schedule.remove',
    updateCommand: 'schedule.update',
    createCommand: 'schedule.add',
  },
  REPORTSCHEDULEREPORTS: {
    ...defaults,
    domain: 'reporting',
    fetchCommand: 'schedulereports.list',
    table: 'tbreportschedulereports',
  },
  SALESPERIODS: {
    ...defaults,
    tb: 'salesperiods',
    table: 'tbsalesperiods',
  },
  SALETYPES: {
    ...defaults,
    tb: 'saletypes',
    table: 'tbsaletypes',
  },
  SAVEDREPORTS: {
    ...defaults,
    domain: 'reporting',
    fetchCommand: 'savedReports.list',
    removeCommand: 'savedReports.remove',
    updateCommand: 'savedReports.update',
    createCommand: 'savedReports.add',
  },
  SCHEDULETASKS: {
    ...defaults,
    tb: 'scheduletasks',
    table: 'tbscheduletasks',
  },
  SERVICETIMEPARAMS: {
    ...defaults,
    tb: 'servicetimeparams',
    table: 'tbserviceparams',
    updateCommand: 'servicetimeparam_update',
  },
  SETMENUITEMS: {
    ...defaults,
    tb: 'setmenuitems',
    table: 'tbsetmenuitems',
    sequenceField: 'fdSeq',
    groupField: 'fdSetMenuGUID',
  },
  SETMENUS: {
    ...defaults,
    tb: 'setmenus',
    table: 'tbsetmenus',
  },
  SIZES: {
    ...defaults,
    tb: 'sizes',
    table: 'tbsizes',
  },
  STAFF: {
    ...defaults,
    tb: 'staff',
    table: 'tbstaff',
  },
  STAFFGROUPS: {
    ...defaults,
    tb: 'staffgroups',
    table: 'tbstaffgroups',
  },
  STOCK: {
    ...defaults,
    tb: 'stock',
    table: 'tbstock',
    domain: 'stock control',
  },
  SUBGROUPS: {
    ...defaults,
    tb: 'subgroups',
    table: 'tbsubgroups',
    sequenceField: 'fdSeq',
  },
  SUBLOCATIONS: {
    ...defaults,
    tb: 'sublocations',
    table: 'tbsublocations',
  },
  SUPPLIERPRODUCTS: {
    ...defaults,
    tb: 'supplierproducts',
    table: 'tbsupplierproducts',
  },
  SUPPLIERS: {
    ...defaults,
    tb: 'suppliers',
    table: 'tbsuppliers',
  },
  SURCHARGES: {
    ...defaults,
    tb: 'surcharges',
    table: 'tbsurcharges',
    domain: 'promotions',
  },
  SURCHARGEGROUPS: {
    ...defaults,
    tb: 'surchargegroups',
    table: 'tbsurchargegroups',
    domain: 'promotions',
  },
  SURCHARGEAVAILABILITY: {
    ...defaults,
    tb: 'surchargeavailability',
    table: 'tbsurchargeavailability',
    domain: 'promotions',
  },
  SURCHARGERESTRICTIONS: {
    ...defaults,
    tb: 'surchargerestrictions',
    table: 'tbsurchargerestrictions',
    domain: 'promotions',
    updateCommand: 'linktable.update',
  },
  SURCHARGETRIGGERS: {
    ...defaults,
    tb: 'surchargetriggers',
    table: 'tbsurchargetriggers',
    domain: 'promotions',
    updateCommand: 'linktable.update',
  },
  SYSTEMMODULES: {
    ...defaults,
    tb: 'modules',
    table: 'tbmodules',
    domain: 'system',
  },
  SYSTEMPLUGINS: {
    ...defaults,
    tb: 'plugins',
    table: 'tbplugins',
    domain: 'system',
  },
  TABS: {
    ...defaults,
    tb: 'tabs',
    table: 'tbtabs',
  },
  TABLES: {
    ...defaults,
    tb: 'tables',
    table: 'tbtables',
  },
  TABLERECS: {
    ...defaults,
    tb: 'tablerecs',
    table: 'tbtablerecs',
  },
  TAKINGS: {
    ...defaults,
    tb: 'takings',
  },
  SALES: {
    ...defaults,
    tb: 'sales',
    table: 'tbsales',
  },
  TAX: {
    ...defaults,
    tb: 'tax',
    table: 'tbtax',
  },
  TRANSACTIONS: {
    ...defaults,
    tb: 'transactions',
    orderby: 'fdDateTime, fdSeq',
  },
  TRANSFERS: {
    ...defaults,
    tb: 'transfers',
    table: 'tbtransfers',
    domain: 'stock control',
  },
  TRANSFERITEMS: {
    ...defaults,
    tb: 'transferitems',
    table: 'tbtransferitems',
    domain: 'stock control',
  },
  UMS: {
    ...defaults,
    tb: 'ums',
    table: 'tbums',
  },
  USERS: {
    ...defaults,
    tb: 'users',
    table: 'tbusers',
    domain: 'settings',
  },
  PAGEPERMISSIONS: {
    fetchCommand: 'permissions.get',
  },
  USERAPPS: {
    ...defaults,
    tb: 'userapps',
    table: 'tbuserapps',
    domain: 'settings',
  },
  USERTABLECOLUMNS: {
    fetchCommand: 'table.columnlist',
    updateCommand: 'table.columns',
    domain: 'settings',
  },
  VENUEITEMS: {
    ...defaults,
    tb: 'venueitems',
    table: 'tbvenueitems',
  },
  VENUESREPORTS: {
    ...defaults,
    domain: 'reporting',
    fetchCommand: 'venuereports.list',
    removeCommand: 'report.remove',
    updateCommand: 'report.update',
    createCommand: 'report.add',
  },
  WASTAGE: {
    ...defaults,
    tb: 'wastage',
    table: 'tbwastage',
    domain: 'stock control',
  },
  WASTAGEITEMS: {
    ...defaults,
    tb: 'wastageitems',
    table: 'tbwastageitems',
    domain: 'stock control',
  },
  WRITEOFFS: {
    ...defaults,
    tb: 'stockchanges',
  },
  ZONES: {
    ...defaults,
    tb: 'zones',
    table: 'tbzones',
  },
};

export function fetchData(name, options, blank, callback) {
  const { fetchCommand, removeCommand, updateCommand, createCommand, ...opts } = stores[
    name
  ];
  return makeAjaxRequest(
    name,
    {
      command: fetchCommand,
      ...opts,
      ...options,
    },
    'FETCH',
    null,
    blank,
    callback,
  );
}

export function removeRecord(name, record, options) {
  return makeAjaxRequest(
    name,
    {
      command: stores[name].removeCommand,
      tb: stores[name].tb,
      domain: stores[name].domain,
      fdGUID: record.fdGUID,
      ...options,
    },
    'DELETE',
  );
}

export function updateRecord(name, guid, field, value, oldvalue, options) {
  return makeAjaxRequest(
    name,
    {
      command: stores[name].updateCommand,
      table: stores[name].table,
      domain: stores[name].domain,
      guid,
      field,
      value,
      oldvalue,
      ...options,
    },
    'UPDATE',
    { oldvalue },
  );
}

export function createRecord(name, data, addLast, callback = null) {
  const { isNewRecord, ...myData } = data;
  return makeAjaxRequest(
    name,
    {
      command: stores[name].createCommand,
      tb: stores[name].tb,
      domain: stores[name].domain,
      ...myData,
      addLast,
    },
    'INSERT',
    myData,
    null,
    callback,
  );
}

export function updateSequence(name, guid, seq, callback = null, groupValue) {
  return makeAjaxRequest(
    name,
    {
      table: stores[name].table,
      domain: stores[name].domain,
      command: 'update.sequence',
      guid,
      seq,
      sortBy: stores[name].sequenceField,
      groupField: stores[name].groupField,
      groupValue,
    },
    'UPDATE',
    null,
    null,
    callback,
  );
}

export function createLocalRecord(name, data, addLast) {
  return {
    data,
    type: `CREATE_LOCAL_${name}`,
    addLast,
  };
}

export function updateLocalRecord(name, guid, field, value, obj) {
  return {
    type: `UPDATE_LOCAL_${name}`,
    guid,
    field,
    value,
    obj,
  };
}

export function updateLinkTable(name, groupGuid, groupField, itemGuids, itemField) {
  return makeAjaxRequest(
    name,
    {
      command: 'linktable.update',
      table: stores[name].table,
      domain: stores[name].domain,
      groupGuid,
      groupField,
      itemGuids,
      itemField,
    },
    'LINK_UPDATE',
  );
}

// Below is for 'Settings' module

// remove user record
export function removeUserRecord(name, record, domain) {
  return makeAjaxRequest(
    name,
    {
      command: stores[name].removeCommand,
      tb: stores[name].tb,
      domain: stores[name].domain,
      fdUserID: record.fdUserID,
    },
    'DELETE',
  );
}

// change password
export function changeUserPassword(name, userID, password) {
  return makeAjaxRequest(
    name,
    {
      command: 'user.changepwd',
      tb: stores[name].tb,
      domain: stores[name].domain,
      user: userID,
      pwd: password,
    },
    'UPDATE',
  );
}

// stock control

export function purchasingDelivery(name, purchaseID, countdoc, countDateTime) {
  return makeAjaxRequest(
    name,
    {
      command: 'purchasing.delivery',
      tb: stores[name].tb,
      domain: stores[name].domain,
      guid: purchaseID,
      countdoc: countdoc,
      countdatetime: countDateTime,
    },
    'UPDATE',
  );
}

export function systemInvoiceNo(name, purchaseID, supplierID, invoiceNo) {
  return makeAjaxRequest(name, {
    command: 'system.invoiceno',
    tb: stores[name].tb,
    domain: stores[name].domain,
    purchase: purchaseID,
    supplier: supplierID,
    invoiceno: invoiceNo,
  });
}

export function systemCheckDeliveryDate(name, date) {
  return makeAjaxRequest(
    name,
    {
      command: 'system.checkdeliverydate',
      tb: stores[name].tb,
      domain: stores[name].domain,
      date: date,
    },
    'UPDATE',
  );
}

export function purchasingComplete(name, purchaseID, items, countDoc) {
  return makeAjaxRequest(name, {
    command: 'purchasing.complete',
    tb: stores[name].tb,
    domain: stores[name].domain,
    guid: purchaseID,
    items: items,
    countdoc: countDoc,
  });
}

// Stock Control - Counting
export function countNow(name, guid, type) {
  return makeAjaxRequest(
    name,
    {
      command: 'count.now',
      tb: stores[name].tb,
      domain: stores[name].domain,
      guid: guid,
      type: type,
    },
    'UPDATE',
  );
}

export function countReconcile(name, guid) {
  return makeAjaxRequest(
    name,
    {
      command: 'count.reconcile',
      tb: stores[name].tb,
      domain: stores[name].domain,
      guid: guid,
    },
    'UPDATE',
  );
}

export function countGet(name, docno, location) {
  return makeAjaxRequest(
    name,
    {
      command: 'count.get',
      tb: stores[name].tb,
      domain: stores[name].domain,
      docno: docno,
      location: location,
    },
    'UPDATE',
  );
}

export function countReset(name, locationID) {
  return makeAjaxRequest(
    name,
    {
      command: 'count.reset',
      tb: stores[name].tb,
      domain: stores[name].domain,
      location: locationID,
    },
    'UPDATE',
  );
}

export function countCancel(name, locationID) {
  return makeAjaxRequest(
    name,
    {
      command: 'count.cancel',
      tb: stores[name].tb,
      domain: stores[name].domain,
      location: locationID,
    },
    'UPDATE',
  );
}

export function countComplete(name, guid) {
  return makeAjaxRequest(
    name,
    {
      command: 'count.complete',
      tb: stores[name].tb,
      domain: stores[name].domain,
      guid: guid,
    },
    'UPDATE',
  );
}

export function countFill(name, guid, locationID) {
  return makeAjaxRequest(
    name,
    {
      command: 'count.fill',
      tb: stores[name].tb,
      domain: stores[name].domain,
      guid: guid,
      locationID: locationID,
    },
    'UPDATE',
  );
}

export function manualSalesList(name, deviceid) {
  return makeAjaxRequest(
    name,
    {
      command: 'manualsales.list',
      tb: stores[name].tb,
      domain: stores[name].domain,
      deviceid: deviceid,
    },
    'FETCH',
  );
}

export function manualSalesReset(name, deviceid) {
  return makeAjaxRequest(name, {
    command: 'manualsales.reset',
    tb: stores[name].tb,
    domain: stores[name].domain,
    deviceid: deviceid,
  });
}

export function manualSalesCommit(name, deviceid, devno, data) {
  return makeAjaxRequest(name, {
    command: 'manualsales.commit',
    tb: stores[name].tb,
    domain: stores[name].domain,
    deviceid: deviceid,
    devno: devno,
    data: data,
  });
}

export function checkProductsInVenues(name, plus, venues) {
  return makeAjaxRequest(
    name,
    {
      command: 'check.productsvenues',
      tb: stores[name].tb,
      domain: stores[name].domain,
      plus: plus,
      venues: venues,
    },
    'CHECK_PRODUCTS',
  );
}

export function pushToVenue(name, products, venues) {
  return makeAjaxRequest(name, {
    command: 'push.venues',
    tb: stores[name].tb,
    domain: stores[name].domain,
    products: products,
    venues: venues,
  });
}

export function finaliseWastage(name, wastageID, finaliseDateTime) {
  return makeAjaxRequest(name, {
    command: 'finalise.wastage',
    tb: stores[name].tb,
    domain: stores[name].domain,
    guid: wastageID,
    finaliseDateTime: finaliseDateTime,
  });
}

export function transferNow(name, transferID) {
  return makeAjaxRequest(name, {
    command: 'transfer.now',
    tb: stores[name].tb,
    domain: stores[name].domain,
    guid: transferID,
  });
}

export function returnNow(name, guid, items, countdoc, countDateTime) {
  return makeAjaxRequest(name, {
    command: 'return.now',
    tb: stores[name].tb,
    domain: stores[name].domain,
    guid,
    items,
    countdoc,
    countdatetime: countDateTime,
  });
}

export function returnComplete(name, guid, items, countdoc) {
  return makeAjaxRequest(name, {
    command: 'returns.complete',
    tb: stores[name].tb,
    domain: stores[name].domain,
    guid,
    items,
    countdoc,
  });
}

export function systemCreditNo(name, purchaseID, supplierID, creditNo) {
  return makeAjaxRequest(name, {
    command: 'system.creditno',
    tb: stores[name].tb,
    domain: stores[name].domain,
    purchase: purchaseID,
    supplier: supplierID,
    creditno: creditNo,
  });
}

export function pluginsAdd(name, pluginName, category, module, guid, data) {
  const { isNewRecord, ...myData } = data;
  return makeAjaxRequest(
    name,
    {
      command: 'plugins.add',
      tb: stores[name].tb,
      domain: stores[name].domain,
      name: pluginName,
      category,
      module,
      guid,
      ...myData,
    },
    'INSERT',
    myData,
  );
}

export function backupModule(name, pluginName, pluginUrl) {
  return makeAjaxRequest(name, {
    command: 'modules.backup',
    domain: 'system',
    name: pluginName,
    url: pluginUrl,
  });
}

export function removeModule(name, pluginName, pluginUrl) {
  return makeAjaxRequest(name, {
    command: 'modules.remove',
    domain: 'system',
    name: pluginName,
    url: pluginUrl,
  });
}

export function logPurge(name) {
  return makeAjaxRequest(name, {
    command: 'log.purge',
    domain: 'system',
  });
}

export function purgeBackups(name) {
  return makeAjaxRequest(name, {
    command: 'data.purge',
    domain: 'system',
  });
}

export function forceRestart(name, device = '', ip = '', command) {
  if (!command) command = name.toLowerCase() + '.restart';
  return makeAjaxRequest(name, {
    command,
    domain: 'system',
    device,
    ip,
  });
}

export function updateVersion(name, device = '', ip = '', command) {
  if (!command) command = name.toLowerCase() + '.checkforupdate';
  return makeAjaxRequest(name, {
    command: name.toLowerCase() + '.checkforupdate',
    domain: 'system',
    device,
    ip,
  });
}

export function addUserModules(name, data) {
  return makeAjaxRequest(
    name,
    {
      command: 'add.modules',
      tb: stores[name].tb,
      domain: stores[name].domain,
      ...data,
    },
    'INSERT',
  );
}

export function forceSystemRestart(name, device = '', ip = '', command) {
  if (!command) command = name.toLowerCase() + '.systemrestart';
  return makeAjaxRequest(name, {
    command,
    domain: 'system',
    device,
    ip,
  });
}
